import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { HTMLAttributes } from 'react';
export interface FAQItemProps extends HTMLAttributes<HTMLDivElement> {
	question: string;
	answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer, ...props }) => {
	return (
		<div className="w-full sm:w-1/2 md:w-1/3 py-3 px-2 text-center" {...props}>
			<h2 className="font-bold text-2xl md:text-3xl">{question}</h2>
			{answer.startsWith('function') ? <MDXRenderer>{answer}</MDXRenderer> : <p>{answer}</p>}
		</div>
	);
};

export default FAQItem;
