import FAQItem from '@components/FAQ/Item';
import FAQList from '@components/FAQ/List';
import Link from '@components/Link';
import background from '@images/hackcu8/Asset14.svg';
//import hedwig from '@images/hackcu8/hedwig_svg_updated.svg';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { HTMLAttributes } from 'react';
import SponsorList from '@components/Sponsors/List';
import Img from 'gatsby-image';

import './fonts.css';
import styles from './custom.module.css';
import clsx from 'clsx';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Footer from '@components/Layout/Footer';
import HackCU8Header from '@components/Layout/HeaderHackCU8';

const HackathonCard: React.FC<HTMLAttributes<HTMLDivElement>> = ({
	className,
	children,
	...rest
}) => (
	<div {...rest} className={clsx('bg-hackcu8-gray-500 rounded-md p-2', className)}>
		{children}
	</div>
);

const IndexPage = () => {
	const {
		data,
		schedule1,
		schedule2,
		FAQdata,
		sponsorsData: { nodes: sponsors },
	} = useStaticQuery(graphql`
		{
			data: allHackcu8TracksYaml {
				nodes {
					title
					description
				}
			}

			schedule1: allHackcu8Schedule1Yaml {
				nodes {
					time
					event
				}
			}

			schedule2: allHackcu8Schedule2Yaml {
				nodes {
					time: time2
					event: event2
				}
			}

			FAQdata: allHackcu8FaqYaml {
				nodes {
					question
					answer
				}
			}

			sponsorsData: allSponsorsYaml {
				nodes {
					name
					tier
					url
					image {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`);

	return (
		<>
			<GatsbySeo
				title="HackCU 8"
				description="Our 8th hackathon, which will be in-person this year."
			/>
			<HackCU8Header />

			<div className="static">
				<img
					className="w-full relative bottom-0 select-none"
					src={background as any}
					alt="Magic"
					style={{
						zIndex: -2,
					}}
					id="home"
				/>
				{/* <div className={clsx(styles.train, 'absolute bottom-0 select-none')}>
					<img
						src={hedwig as string}
						alt="hedwig"
						className={clsx('w-0 sm:w-16 md:w-96 relative bottom-0 font', styles.trainImg)}
						style={{ zIndex: -2, fontFamily: 'HarryP' }}
					/>
				</div> */}

				<div className="absolute inset-0 text-center sm:center-auto sm:bottom-auto pb-10">
					<div className="justify-center items-center align-bottom h-screen left-11 mt-12">
						<h1
							className={clsx('text-center sm:text-center', styles.title)}
							style={{
								fontFamily: '"Garamond", sans-serif',
							}}
						>
							HACKCU 8
						</h1>
						<h2
							className="font-bold text-4xl text-center sm:right"
							style={{ fontFamily: '"Garamond", sans-serif' }}
						>
							{' '}
							March 5 - 6{' '}
						</h2>
					</div>
				</div>
			</div>

			<div className="w-full bg-hackcu8-background-500">
				<div className="mx-auto max-w-7xl px-3 xl:px-0">
					<div className="space-y-8 pt-5">
						<HackathonCard>
							<h1 className="text-center font-bold text-5xl" id="about">
								About
							</h1>
							<p className="pt-3 pb-3">
								On March 5-6, we will be hosting our flagship annual hackathon, HackCU 8, in-person!
								You will have the chance to create projects in any of the provided tracks. Submit
								your project to win a number of special prizes, or attend a few of our many
								workshops and seminars. HackCU 8 will be hosted at the Jennie Smoly Caruthers
								Biotechnology Building on CU Boulder East Campus. Space is limited so register as
								soon as possible!
							</p>
						</HackathonCard>

						<HackathonCard>
							<h1 className="text-center font-bold text-5xl" id="tracks">
								Tracks
							</h1>

							<div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
								{data.nodes.map(({ title, description }) => (
									<div className="py-3 px-2 text-center" key={title}>
										<h2 className="font-bold text-3xl mb-1">{title}</h2>
										{description.startsWith('function') ? (
											<MDXRenderer>{description}</MDXRenderer>
										) : (
											<p>{description}</p>
										)}
									</div>
								))}
							</div>
						</HackathonCard>

						<HackathonCard className="px-5">
							<div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
								<div>
									<h1 className="text-center font-bold text-5xl mb-3">Our Story</h1>
									<p className="pt-3 pb-3">
										HackCU was started in 2014 by a group of passionate students who wanted to
										provide other students the time and space to work on projects they don&apos;t
										normally work on through school. Since then we&apos;ve been committed to
										fulfilling our mission of bringing you high-quality, inclusive events open to
										people of all levels of experience.
									</p>
								</div>
								<div>
									<h1 className="text-center font-bold text-5xl mb-3">Why You?</h1>
									<p className="pt-3 pb-3">
										Like to write programs? Dig into interesting datasets? Design creative works? Or
										maybe you&apos;ve never written a line of code? We will provide the space and
										the resources for you to express your creativity, ingenuity, and determination
										to create anything you want to. Whether you&apos;re building your first website,
										dabbling in Photoshop, or hacking with an Oculus Rift, HackCU is for you. HackCU
										is for people of all backgrounds and experiences. So, come and join us!
									</p>
								</div>
							</div>
						</HackathonCard>

						<HackathonCard>
							<h1 className="text-center font-bold text-5xl" id="faq">
								FAQs
							</h1>
							<FAQList>
								{FAQdata.nodes.map(({ question, answer }) => (
									<FAQItem key={question} question={question} answer={answer} />
								))}
							</FAQList>
						</HackathonCard>

						<HackathonCard>
							<h1 className="mb-5 text-center font-bold text-5xl" id="events">
								Schedule
							</h1>

							<div className="flex flex-column flex-row justify-center">
								<div className="mx-3 flexGrow-1">
									<h1 className="font-bold text-3xl text-center mb-3">Day 1</h1>
									<table className="border-collapse w-full">
										<thead className="text-md">
											<th>Time</th>
											<th>Event</th>
										</thead>
										<tbody>
											{schedule1.nodes.map(({ time, event }, index) => (
												<tr
													key={event}
													style={{ borderTop: index === 0 ? '2px solid white' : '1px solid white' }}
												>
													<td className="p-1  text-center">
														<p>{time}</p>
													</td>

													<td className="p-1 text-center">
														{event.startsWith('function') ? (
															<MDXRenderer>{event}</MDXRenderer>
														) : (
															<p>{event}</p>
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<div className="mx-3 flexGrow-1">
									<h1 className="font-bold text-3xl text-center mb-3">Day 2</h1>
									<table className="border-collapse w-full">
										<thead className="text-md">
											<th>Time</th>
											<th>Event</th>
										</thead>
										<tbody>
											{schedule2.nodes.map(({ time, event }, index) => (
												<tr
													key={event}
													style={{ borderTop: index === 0 ? '2px solid white' : '1px solid white' }}
												>
													<td className="p-1 text-center">
														<p>{time}</p>
													</td>

													<td className="p-1 text-center">
														{event.startsWith('function') ? (
															<MDXRenderer>{event}</MDXRenderer>
														) : (
															<p>{event}</p>
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</HackathonCard>

						{/* <HackathonCard className="space-y-4 text-center">
							<h1 className="text-center font-bold text-5xl" id="mentor">
								Volunteer and Mentor
							</h1>
							<h2 className="text-center font-bold text-2xl">Want to build HackCU with us?</h2>
							<h2 className="pb-5 text-center font-bold text-2xl">
								Help us make this happen and welcome over 400 awesome hackers.
							</h2> */}
						{/* <Link
								outside
								to="https://docs.google.com/forms/d/e/1FAIpQLSd_jF9T_1fnYySfeaj56V3VS0SXGYiSWonZROOKiAY0FnS6Og/viewform?usp=sf_link"
								className="px-3 py-8"
							>
								<button className="font-bold text-xl cursor-pointer px-3 py-2 text-white bg-hackcu8-primary-500 rounded-md">
									Volunteer
								</button>
							</Link> */}
						{/* 
							<Link
								outside
								to="https://docs.google.com/forms/d/e/1FAIpQLScyVwRuWLHNPF_4y_O1pIi3Q1Kh3dTIjxYXDdrjH3YJCx-nrw/viewform?usp=sf_link"
								className="px-3 py-8"
							>
								<button className="font-bold text-xl cursor-pointer px-3 py-2 text-white bg-hackcu8-primary-500 rounded-md">
									Mentor
								</button>
							</Link>
						</HackathonCard> */}

						<div className="bg-hackcu8-gray-500 rounded-md p-2 text-center">
							<h1 className="text-center font-bold text-5xl" id="sponsors">
								Sponsors
							</h1>

							<SponsorList>
								{sponsors.map(({ name, url, image }) => (
									<div key={name} className="inline-block my-auto" data-testid="sponsor-item">
										<Link outside to={url} className="p-3 block w-full" data-testid="sponsor-item">
											<Img
												imgStyle={{
													objectFit: 'contain',
												}}
												alt={`${name} logo`}
												fluid={image.childImageSharp.fluid}
											/>
										</Link>
									</div>
								))}
							</SponsorList>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	);
};

export default IndexPage;
